<template>
  <div class="text-center">
    <spinner
      :diameter="25"
      style="padding: 10px"
      v-if="progress.isSaving"
    ></spinner>
    <div v-else>
      <md-button
        class="md-raised md-primary"
        @click="previosStep(previousStep, previousStep)"
        >Previous</md-button
      >
      <md-button class="md-raised md-primary" @click="handlePatientSave()"
        >Finish</md-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["previousStep"],
  inject: ["managePatient", "handlePatientSave", "progress", "previosStep"],
};
</script>
