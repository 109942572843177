<template>
  <ValidationListener
    @getIsValid="(isValid) => (patientForm.isPersonalDataValid = isValid)"
  >
    <div>
      <div class="d-flex justify-content-center">
        <profile-image
          defaultSrc="patient"
          :options="{
            feature: 'User',
            featureId: patientForm.postData.userID,
            category: 'PROFILE',
          }"
          @getFileUploadRef="(ref) => (patientForm.fileUploadRef = ref)"
        ></profile-image>
      </div>
      <div class="md-layout">
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
        >
          <div class="d-flex mr-2">
            <label class="labeltext mr-2">Title</label>
            <span style="font-weight: bold">Patient</span>
          </div>
          <ValidationHandler
            :name="patientForm.postData.person.firstName"
            rules="required|alpha_spaces"
            v-slot="{ error }"
          >
            <TextInput
              label="First Name *"
              placeholder="First Name *"
              icon="perm_identity"
              :required="true"
              v-model="patientForm.postData.person.firstName"
              :error="error"
              icons="perm_identity"
              tooltip=" Please enter your first name"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.person.lastName"
            rules="required|alpha_spaces|min:2"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Last Name *"
              placeholder="Last Name *"
              icon="perm_identity"
              :required="true"
              v-model="patientForm.postData.person.lastName"
              :error="error"
              icons="record_voice_over"
              tooltip=" Please enter your last name"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.person.gender"
            rules="required"
            v-slot="{ error }"
          >
            <label class="labeltext">Gender *</label>
            <div class="mb-3 d-flex">
              <radio-button-input
                name="gender"
                :options="
                  master.genderList.map((x) => ({
                    value: x.value,
                    text: x.displayValue,
                  }))
                "
                v-model="patientForm.postData.person.gender"
                :error="error"
              />
            </div>
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.person.dob"
            rules="required"
            v-slot="{ error }"
          >
            <label class="labeltext">DOB</label>
            <div class="mb-3 d-flex">
              <DateDropDown
                ref="dobRef"
                v-model="patientForm.postData.person.dob"
                :default-date="patientForm.postData.person.dob"
                :max="2200"
                :min="1920"
              />
              <span class="md-error">{{ error }}</span>
            </div>
          </ValidationHandler>
          <div class="d-flex">
            <div class="w-50 mr-1">
              <ValidationHandler
                :name="patientForm.postData.height1"
                rules="required"
                v-slot="{ error }"
              >
                <div class="text-field">
                  <select-input
                    name="height1"
                    displayName="Height Feet*"
                    placeHolder="Height Feet *"
                    :options="
                      heightFeets.map((x) => ({
                        value: x.value,
                        text: x.text,
                      }))
                    "
                    v-model="patientForm.postData.height1"
                    :required="true"
                    :error="error"
                    tooltip=" Please enter your Height(Feet)"
                  />
                </div>
              </ValidationHandler>
            </div>
            <div class="w-50 mr-1">
              <ValidationHandler
                :name="patientForm.postData.height2"
                rules="required"
                v-slot="{ error }"
              >
                <div class="text-field">
                  <select-input
                    name="height1"
                    displayName="Height Inch*"
                    placeHolder="Height Inch *"
                    :options="
                      heightInches.map((x) => ({
                        value: x.value,
                        text: x.text,
                      }))
                    "
                    v-model="patientForm.postData.height2"
                    :required="true"
                    :error="error"
                    tooltip=" Please enter your Height(Inch)"
                  />
                </div>
              </ValidationHandler>
            </div>
          </div>
        </div>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
        >
          <label class="labeltext">Contact Details</label>
          <ValidationHandler
            :name="patientForm.postData.person.email"
            rules="required|email"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Email *"
              placeholder="Email *"
              icon="mail_outline"
              :required="false"
              v-model="patientForm.postData.person.email"
              :error="error"
              icons="email"
              tooltip=" Please enter your email"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.person.cellNumber"
            rules="required|min:14|max:18"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Phone Number *"
              placeholder="Phone Number *"
              icon="settings_cell"
              :required="true"
              v-model="patientForm.postData.person.cellNumber"
              v-mask="'(###) ###-########'"
              :error="error"
              icons="smartphone"
              tooltip=" Please enter your Phone number"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.address.address1"
            rules="required"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Address Line 1 *"
              placeholder="Address Line 1 *"
              icon="place"
              :required="true"
              v-model="patientForm.postData.address.address1"
              :error="error"
              icons="room"
              tooltip=" Please enter your primary address"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="patientForm.postData.address.address2"
            rules=""
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Address Line 2"
              placeholder="Address Line 2"
              icon="place"
              :required="true"
              v-model="patientForm.postData.address.address2"
              :error="error"
              icons="room"
              tooltip=" Please enter your Secondary address"
            />
          </ValidationHandler>
          <div class="d-flex">
            <div class="w-50 mr-1">
              <ValidationHandler
                :name="patientForm.postData.address.city"
                rules="required|alpha_spaces"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="City *"
                  placeholder="City *"
                  icon="location_city"
                  :required="true"
                  v-model="patientForm.postData.address.city"
                  :error="error"
                  icons="domain"
                  tooltip=" Please enter your city"
                />
              </ValidationHandler>
            </div>
            <div class="w-50">
              <ValidationHandler
                :name="patientForm.postData.address.state"
                rules="required"
                v-slot="{ error }"
              >
                <div class="text-field">
                  <select-input
                    name="state"
                    displayName="State *"
                    placeHolder="state *"
                    :options="
                      master.stateList.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="patientForm.postData.address.state"
                    :required="true"
                    :error="error"
                    tooltip=" Please select your state"
                  />
                </div>
              </ValidationHandler>
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-1">
              <ValidationHandler
                :name="patientForm.postData.address.country"
                rules="required"
                v-slot="{ error }"
              >
                <div class="text-field">
                  <select-input
                    name="country"
                    displayName="Country *"
                    placeHolder="country"
                    icon="outlined_flag"
                    :options="
                      master.countryCodeList.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="patientForm.postData.address.country"
                    :required="true"
                    :error="error"
                    tooltip=" Please select your Country"
                  />
                </div>
              </ValidationHandler>
            </div>
            <div class="w-50">
              <ValidationHandler
                :name="patientForm.postData.address.zipCode"
                rules="required|min:5|max:16"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="Zip Code *"
                  placeholder="Zip Code *"
                  :required="true"
                  v-model="patientForm.postData.address.zipCode"
                  :error="error"
                  v-mask="'#####-#####-####'"
                  tooltip=" Please enter your Zipcode "
                />
              </ValidationHandler>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationListener>
</template>
<script>
import TextInput from "../../../components/controls/TextInput.vue";
import SelectInput from "../../../components/controls/SelectInput.vue";
import RadioButtonInput from "../../../components/controls/RadioButtonInput.vue";
import DateDropDown from "../../../components/Globechek/DateDropDown.vue";
import ProfileImage from "../../../components/Globechek/Register/ProfileImage.vue";

export default {
  inject: [
    "patientForm",
    "master",
    "heightFeets",
    "heightInches",
    "managePatient",
    "checkPermission",
    "Permissions",
    "navigationBlocker",
  ],
  data() {
    return {
      patient: {
        firstName: 0,
        lastName: 0,
        gender: 0,
        dob: 0,
        height1: 0,
        height2: 0,
        email: 0,
        cellNumber: 0,
        address1: 0,
        address2: 0,
        city: 0,
        state: 0,
        country: 0,
        zipCode: 0,
      },
    };
  },
  watch: {
    "patientForm.postData.person.dob": function () {
      console.log("####################", this.patientForm);
      if (this.patientForm.canSetDOB && this.patientForm.dobObj) {
        this.$refs.dobRef._data.selectedDay = this.patientForm.dobObj.day;
        this.$refs.dobRef._data.selectedMonth = this.patientForm.dobObj.month;
        this.$refs.dobRef._data.selectedYear = this.patientForm.dobObj.year;
        this.patientForm.canSetDOB = false;
        this.patient.dob = this.patient.dob + 1;
        if (this.$route.path.includes("edit") && this.patient.dob > 1)
          this.navigationBlocker.val = false;
        else if (
          this.$route.path.includes("add") |
          this.$route.path.includes("/register/patient")
        ) {
          this.navigationBlocker.val = false;
        }
      }
    },
    "patientForm.postData.person.firstName"() {
      this.patient.firstName = this.patient.firstName + 1;
      if (this.$route.path.includes("edit") && this.patient.firstName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.person.lastName"() {
      this.patient.lastName = this.patient.lastName + 1;
      if (this.$route.path.includes("edit") && this.patient.lastName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.person.gender"() {
      this.patient.gender = this.patient.gender + 1;
      if (this.$route.path.includes("edit") && this.patient.gender > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.height1"() {
      this.patient.height1 = this.patient.height1 + 1;
      if (this.$route.path.includes("edit") && this.patient.height1 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.height2"() {
      this.patient.height2 = this.patient.height2 + 1;
      if (this.$route.path.includes("edit") && this.patient.height2 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.person.email"() {
      this.patient.email = this.patient.email + 1;
      if (this.$route.path.includes("edit") && this.patient.email > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.person.cellNumber"() {
      this.patient.cellNumber = this.patient.cellNumber + 1;
      if (this.$route.path.includes("edit") && this.patient.cellNumber > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.address1"() {
      this.patient.address1 = this.patient.address1 + 1;
      if (this.$route.path.includes("edit") && this.patient.address1 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.address2"() {
      this.patient.address2 = this.patient.address2 + 1;
      if (this.$route.path.includes("edit") && this.patient.address2 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.city"() {
      this.patient.city = this.patient.city + 1;
      if (this.$route.path.includes("edit") && this.patient.city > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.state"() {
      this.patient.state = this.patient.state + 1;
      if (this.$route.path.includes("edit") && this.patient.state > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.country"() {
      this.patient.country = this.patient.country + 1;
      if (this.$route.path.includes("edit") && this.patient.country > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.address.zipCode"() {
      this.patient.zipCode = this.patient.zipCode + 1;
      if (this.$route.path.includes("edit") && this.patient.zipCode > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
  },
  components: {
    TextInput,
    SelectInput,
    RadioButtonInput,
    DateDropDown,
    ProfileImage,
  },
};
</script>
