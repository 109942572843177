<template>
  <div>
    <loading :active="progress.isDetailsLoading" :can-cancel="true"></loading>
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step
        id="first"
        md-label="Personal Details"
        :md-error="firstStepErr"
        :md-done.sync="first"
      >
        <first-step></first-step>
        <div class="text-center">
          <md-button class="md-raised md-primary" @click="$router.back()"
            >Exit</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click.native="nextStep('first', 'second', true)"
            >Next</md-button
          >
        </div>
      </md-step>
      <md-step id="second" md-label="History" :md-done.sync="second">
        <second-step></second-step>
        <div class="text-center">
          <md-button
            class="md-raised md-primary"
            @click="previosStep('first', 'first')"
            >Previous</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click.native="nextStep('second', 'third')"
            >Next</md-button
          >
        </div>
      </md-step>
      <md-step
        id="third"
        md-label="Admin Entry"
        :md-error="thirdStepErr"
        :md-done.sync="third"
        v-if="
          checkPermission(Permissions.SuperAdmin) &&
          (managePatient.id || managePatient.page != 'Register')
        "
      >
        <third-step></third-step>
        <div class="text-center">
          <md-button
            class="btn-outline"
            @click="previosStep('second', 'second')"
            >Previous</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click.native="nextStep('third', 'fourth', true)"
            >Next</md-button
          >
        </div>
      </md-step>
      <md-step
        id="fourth"
        md-label="Confirm"
        :md-done.sync="fourth"
        v-if="
          checkPermission(Permissions.SuperAdmin) &&
          (managePatient.id || managePatient.page != 'Register')
        "
      >
        <fourth-step></fourth-step>
        <submition-footer previousStep="third"></submition-footer>
      </md-step>
      <md-step id="third" md-label="Confirm" :md-done.sync="third" v-else>
        <fourth-step></fourth-step>
        <submition-footer previousStep="second"></submition-footer>
      </md-step>
    </md-steppers>
    <confidential-confirmation></confidential-confirmation>
  </div>
</template>
<script>
import FirstStep from "./1_PersonalDetails.vue";
import SecondStep from "./2_PatientHistory.vue";
import ThirdStep from "./3_AdminEntry.vue";
import FourthStep from "./4_ConfirmReservation.vue";
import ConfidentialConfirmation from "./ConfidentialConfirmation.vue";
import SubmitionFooter from "./SubmitionFooter.vue";
export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    ConfidentialConfirmation,
    SubmitionFooter,
  },
  inject: [
    "checkFormValid",
    "progress",
    "checkPermission",
    "Permissions",
    "managePatient",
  ],
  data() {
    return {
      firstStepErr: null,
      secondStepErr: null,
      thirdStepErr: null,
      active: "first",
      first: false,
      second: false,
      third: false,
      fourth: false,
    };
  },
  methods: {
    nextStep(current, next, needValidation) {
      if (needValidation)
        this.checkFormValid(current).then((isValid) => {
          if (isValid) this.moveToNextStep(current, next);
          else this[current + "StepErr"] = "There is an error!";
        });
      else this.moveToNextStep(current, next);
    },
    moveToNextStep(current, next) {
      this[current] = true;
      this[current + "StepErr"] = null;
      if (next) {
        this.active = next;
      }
    },
    previosStep(id, index) {
      this[id] = true;
      if (index) {
        this.active = index;
      }
    },
    exit() {
      this.$router.back();
    },
  },
  provide() {
    return {
      previosStep: this.previosStep,
    };
  },
};
</script>
<style scoped>
.text-center {
  padding-top: 15px;
}
</style>
