<template>
  <ValidationListener
    @getIsValid="(isValid) => (patientForm.isAdminEntryValid = isValid)"
  >
    <div class="mb-4">
      <div class="pt-0 d-flex" style="max-height: 350px; overflow: auto">
        <ValidationHandler
          rules="required"
          v-slot="{ error }"
          v-if="master.organisationList.length > 0"
        >
          <div class="card-sub-card-contents">
            <div class="scroll">
              <div class="checkbox-list" style="border: none; padding: 0">
                <check-box-input
                  label="Clients"
                  :options="
                    master.organisationList.map((x) => ({
                      value: x.clientID,
                      text: x.clientName,
                    }))
                  "
                  v-model="patientForm.postData.organisations"
                  :error="error"
                />
              </div>
            </div>
          </div>
        </ValidationHandler>
        <div v-else>No Clients found.</div>
      </div>
    </div>
  </ValidationListener>
</template>
<script>
export default {
  inject: ["master", "patientForm", "navigationBlocker"],
  data() {
    return {
      org: 0,
    };
  },
  watch: {
    "patientForm.postData.organisations"() {
      this.org = this.org + 1;
      if (this.$route.path.includes("edit") && this.org > 1)
        this.navigationBlocker.val = false;
      else if (this.$route.path.includes("add")) {
        this.navigationBlocker.val = false;
      }
    },
  },
};
</script>
