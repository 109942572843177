<template>
  <div class="date-dropdown-container">
    <div class="date-dropdown-select-wrapper">
      <select
        v-model="selectedMonth"
        @change="updateDays()"
        class="date-dropdown-select month"
      >
        <option
          v-for="(month, index) in months"
          :value="index"
          :key="month.month"
        >
          {{ month.month }}
        </option>
      </select>
    </div>

    <div class="date-dropdown-select-wrapper">
      <select v-model="selectedDay" class="date-dropdown-select day">
        <option v-for="day in days" :key="day.day">
          {{ day.day }}
        </option>
      </select>
    </div>

    <div class="date-dropdown-select-wrapper">
      <select
        v-model="selectedYear"
        @change="updateDays()"
        class="date-dropdown-select year"
      >
        <option v-for="year in years" :key="year">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
const defaultMonths = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export default {
  name: "DateDropDown",
  props: {
    default: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    monthsNames: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      days: [],
      selectedDay: "",
      selectedMonth: "",
      selectedYear: "",
    };
  },

  computed: {
    initialDate() {
      return !!(this.default || this.min);
    },
    specifiedDate() {
      return new Date(this.default);
    },
    minDate() {
      if (this.min);
      return new Date(this.min);
    },
    maxDate() {
      if (this.max);
      return new Date(this.max);
    },
    calculatedDate() {
      const day =
        this.selectedDay >= 10 ? this.selectedDay : `0${this.selectedDay}`;
      const month =
        this.selectedMonth + 1 >= 10
          ? this.selectedMonth + 1
          : `0${this.selectedMonth + 1}`;

      return `${day}.${month}.${this.selectedYear}`;
    },
    dividedNamesOfMonths() {
      if (this.monthsNames)
        var ans = this.monthsNames.replace(/\s/g, "").split(",");
      return ans;
    },
    months() {
      let months = [];

      for (let i = 0; i < 12; i++) {
        if (this.dividedNamesOfMonths)
          months.push(this.dividedNamesOfMonths[i]);
        else months.push(defaultMonths[i]);
      }

      return months.map((month, index) => {
        return { month, selected: index === this.selectedMonth };
      });
    },

    // Computes a list of years.
    years() {
      let years = [];
      for (let i = this.min; i < this.max; i++) {
        years.push(i);
      }
      return years;
    },
  },

  methods: {
    getDays() {
      let days = [];
      let daysNumberOfMonth = new Date(
        this.selectedYear,
        this.selectedMonth + 1,
        0
      ).getDate();

      for (let i = 1; i < daysNumberOfMonth + 1; i++) days.push(i);

      return days.map((day) => {
        return { day, selected: days === this.selectedDay };
      });
    },

    updateDays() {
      this.days = this.getDays();
    },
    sendDate() {
      const date = this.format
        ? this.format(this.calculatedDate)
        : this.calculatedDate;

      this.$emit("input", date);
    },
    setDate() {
      this.updateDays();
      let date;

      if (this.min && this.max && !this.default) {
        date = new Date(this.min);
      } else if (this.default) {
        date = new Date(this.default);
      } else {
        date = new Date();
      }

      if (this.initialDate) this.selectedDay = date.getDate() + 1;
      else this.selectedDay = date.getDate();

      this.selectedDay = date.getDate();
      this.selectedMonth = date.getMonth();
      this.selectedYear = date.getFullYear();

      this.sendDate();
    },
  },

  created() {
    this.setDate();
  },

  updated() {
    this.sendDate();
  },
};
</script>

<style scoped>
.date-dropdown-container {
  display: flex;
}
.date-dropdown-select {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 8px;
  margin-right: 10px;
}
</style>
