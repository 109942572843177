<template>
  <div class="div-table justify-content-center">
    <p class="text-right mr-2"><b>Full Name</b></p>
    <p class="ml-1">
      {{
        patientForm.postData.person.firstName +
        " " +
        patientForm.postData.person.lastName
      }}
    </p>
    <p class="text-right mr-2"><b>Email</b></p>
    <p class="ml-1">
      {{ patientForm.postData.person.email }}
    </p>
    <p class="text-right mr-2"><b>Phone</b></p>
    <p class="ml-1">
      {{ patientForm.postData.person.cellNumber }}
    </p>
  </div>
</template>
<script>
export default {
  inject: ["patientForm"],
};
</script>
<style scoped>
.div-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
