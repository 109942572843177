<template>
  <md-dialog :md-active.sync="managePatient.showConfidentialConfirmation">
    <div class="modal-size-medium">
      <div class="modal-header">Use of Confidential Patient Data</div>
      <div class="modal-content scroll">
        <div class="md-layout-item">
          <p style="margin: 15px; font-size: 0.9rem">
            By choosing to be screened by GlobeChek, you are consenting to the
            use of your depersonalized information collected by the screening by
            both GlobeChek and third parties. All personally identifying
            information (name, address, phone number, email address, photograph,
            etc.) will be removed prior to use or dissemination to a third
            party. Depersonalized data will be analyzed in aggregate form by
            both GlobeChek and third parties for the purpose of statistical
            analysis of eye health across demographic populations and to
            understand the effectiveness of eye screening and treatment over
            time. If you do not agree to the use of your depersonalized data,
            you are not authorized to use GlobeChek products or services.
          </p>
          <br />
          <div class="modal-footer">
            <md-button class="btn-outline" @click="handleCancelClick"
              >Opt out
            </md-button>
            <md-button
              class="md-primary"
              @click="managePatient.showConfidentialConfirmation = false"
              >Proceed
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </md-dialog>
</template>
<script>
export default {
  inject: ["managePatient", "navigationBlocker"],
  methods: {
    handleCancelClick() {
      this.navigationBlocker.val = true;
      this.managePatient.showConfidentialConfirmation = false;
      this.$router.back();
    },
  },
};
</script>
