<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <label class="labeltext">Ocular History</label>
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <div class="text-field">
          <select-input
            name="correctivelense"
            displayName="Wear corrective Lenses ?"
            placeHolder="Wear corrective Lenses ?"
            :options="
              correctiveLense.map((x) => ({
                value: x.value,
                text: x.text,
              }))
            "
            v-model="patientForm.postData.wearCorrectiveLense"
            tooltip=" Please enter your corrective lense details"
          />
        </div>
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <div class="text-field">
          <select-input
            displayName="Previous Eye Surgery"
            placeHolder="Previous Eye Surgery"
            :options="
              master.surgeryList.map((x) => ({
                value: x.id,
                text: x.displayValue,
              }))
            "
            v-model="patientForm.postData.history.surgery"
            tooltip=" Please enter your eye surgery details"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <label class="labeltext">Medical History</label>
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <div class="text-field">
          <select-input
            displayName="Any personal history with Diabetes ?"
            placeHolder="Any personal history with Diabetes ?"
            :options="
              master.diabetesList.map((x) => ({
                value: x.id,
                text: x.displayValue,
              }))
            "
            v-model="patientForm.postData.history.diabeties"
            tooltip=" Please enter your history with diabetes"
          />
        </div>
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <div class="text-field">
          <select-input
            displayName="Any personal history with Cancer ?"
            placeHolder="Any personal history with Cancer ?"
            :options="
              master.cancerList.map((x) => ({
                value: x.id,
                text: x.displayValue,
              }))
            "
            v-model="patientForm.postData.history.cancer"
            tooltip=" Please enter your history with Cancer"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <label class="labeltext">Personal History</label>
      </div>
      <div class="md-layout-item md-size-100">
        <div class="info-block mb-2" style="background: none">
          <check-box-input
            :options="historyList"
            v-model="patientForm.historyChecks"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <textarea
          v-model="patientForm.postData.history.others"
          type="textarea"
          placeholder="Others"
          class="w-100 h-100p"
        ></textarea>
      </div>
      <div class="md-layout-item md-size-100">
        <label class="labeltext">Family History</label>
      </div>
      <div class="md-layout-item md-size-100">
        <div class="info-block mb-2" style="background: none">
          <check-box-input
            :options="familyHistoryList"
            v-model="patientForm.familyHistoryChecks"
          />
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <textarea
          v-model="patientForm.postData.familyHistory.other"
          type="textarea"
          placeholder="Others"
          class="w-100 h-100p"
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import SelectInput from "../../../components/controls/SelectInput.vue";

export default {
  inject: [
    "patientForm",
    "historyList",
    "familyHistoryList",
    "master",
    "correctiveLense",
    "navigationBlocker",
  ],
  components: {
    SelectInput,
  },
  data() {
    return {
      history: {
        wearCorrectiveLense: 0,
        surgery: 0,
        diabeties: 0,
        cancer: 0,
        others: 0,
        familyHistory: 0,
      },
    };
  },
  watch: {
    "patientForm.postData.wearCorrectiveLense"() {
      console.log("watch corrective");
      this.history.wearCorrectiveLense = this.history.wearCorrectiveLense + 1;
      if (
        this.$route.path.includes("edit") &&
        this.history.wearCorrectiveLense > 1
      )
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.history.surgery"() {
      this.history.surgery = this.history.surgery + 1;
      if (this.$route.path.includes("edit") && this.history.surgery > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.history.diabeties"() {
      this.history.diabeties = this.history.diabeties + 1;
      if (this.$route.path.includes("edit") && this.history.diabeties > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.history.cancer"() {
      this.history.cancer = this.history.cancer + 1;
      if (this.$route.path.includes("edit") && this.history.cancer > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.history.others"() {
      this.history.others = this.history.others + 1;
      if (this.$route.path.includes("edit") && this.history.others > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "patientForm.postData.familyHistory.other"() {
      this.history.familyHistory = this.history.familyHistory + 1;
      if (this.$route.path.includes("edit") && this.history.familyHistory > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/patient")
      ) {
        this.navigationBlocker.val = false;
      }
    },
  },
};
</script>
